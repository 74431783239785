import React, { useState, useEffect } from "react";
import GitHubIcon from "./github-mark.svg";
import ComputerIcon from "./computer_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.svg";

const Footer = () => {
  return (
    <div className="footer">
      <p>Made by Brooke Kindleman</p>
      <a href="https://brooke-kindleman.com">
        <img
          src={ComputerIcon}
          style={{
            objectFit: "contain",
            maxWidth: "24px",
            marginInline: "0.5em",
            justifySelf: "center",
            alignSelf: "center",
            flexGrow: 1,
            flexShrink: 1,
          }}
        ></img>
        brooke-kindleman.com
      </a>
      <a href="https://github.com/brooke-k">
        <img
          src={GitHubIcon}
          style={{
            objectFit: "contain",
            maxWidth: "24px",
            marginInline: "0.5em",
            justifySelf: "center",
            alignSelf: "center",
            flexGrow: 1,
            flexShrink: 1,
          }}
        ></img>
        GitHub
      </a>
    </div>
  );
};

export default Footer;
