import React, { useEffect, useState } from "react";
import { linearColorMixer, parseRGB } from "./colorFunctions";

export function getInitialColor(rgb1, rgb2) {
  const d = new Date();
  const label = "color-" + d.getTime().toString(32);
  let newColor;
  if (rgb1 == undefined || rgb2 == undefined) {
    let rgb1 = [0, 0, 0];
    let rgb2 = [255, 255, 255];
    newColor = linearColorMixer(rgb1, rgb2, 0.5);
  } else {
    newColor = linearColorMixer(rgb1, rgb2, 0.5);
  }
  return { label: label, value: newColor };
}

function parseColorCode(colorCode) {
  const longFormat = /[#]*([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})/g;
  const shortFormat = /[#]*([0-9a-fA-F]{1})([0-9a-fA-F]{1})([0-9a-fA-F]{1})/g;
  var colorMatch = longFormat.exec(colorCode);
  if (colorMatch) {
    var newColor =
      "#" +
      colorMatch[1].toLowerCase() +
      colorMatch[2].toLowerCase() +
      colorMatch[3].toLowerCase();
    return newColor;
  } else {
    colorMatch = shortFormat.exec(colorCode);
    if (colorMatch) {
      var newColor =
        "#" +
        colorMatch[1] +
        colorMatch[1] +
        colorMatch[2] +
        colorMatch[2] +
        colorMatch[3] +
        colorMatch[3];
      return newColor;
    } else {
      return null;
    }
  }
}

export const ColorInputArray = ({
  colors,
  setColors,
  canChangeLength = false,
  canChangeLabel = false,
}) => {
  function labelIsValid(label) {
    const labelArr = colors.map((x) => x.label);
    return labelArr.indexOf(label) < 0;
  }

  function getParsedName(label) {
    var parsedName = label.replace(/[\s][\s]+/g, " ");
    parsedName = parsedName.replace(/^[\s]/, "");
    parsedName = parsedName.replace(/[\s]/g, "-");
    parsedName = parsedName.replace(/[^a-zA-Z0-9-]/g, "");
    parsedName = parsedName.toLowerCase();
    return parsedName;
  }

  const setColorValue = (label, newValue) => {
    const updatedColors = colors.map((x) => x);
    const labelArry = colors.map((x) => x.label);
    if (labelArry.indexOf(label) > -1) {
      updatedColors[labelArry.indexOf(label)].value = newValue;
      setColors(updatedColors);
    }
  };

  const setColorLabel = (oldLable, newLabel) => {
    const updatedColors = colors.map((x) => x);
    const labelArry = colors.map((x) => x.label);
    updatedColors[labelArry.indexOf(oldLable)].label = getParsedName(newLabel);
    setColors(updatedColors);
  };

  const handleAddColor = () => {
    if (colors.length < 10) {
      const newColor = getInitialColor(
        parseRGB(colors[colors.length - 1].value),
        parseRGB(colors[colors.length - 2].value)
      );
      var newColors = colors.map((x) => x);
      newColors.push(newColor);
      setColors(newColors);
    }
  };

  function handleRemoveColor(label) {
    const labelArray = colors.map((x) => x.label);
    const labelIndex = labelArray.indexOf(label);
    if (labelIndex > -1 && colors.length > 2) {
      var newColors = colors.map((x) => x);
      newColors.splice(labelIndex, 1);
      setColors(newColors);
    }
  }

  return (
    <div className="color-input-array">
      {colors.length > 9 ? (
        <p style={{ textAlign: "center" }}>Maximum of 10 colours allowed</p>
      ) : (
        <></>
      )}
      {canChangeLength ? (
        <button disabled={colors.length > 9} onClick={handleAddColor}>
          +
        </button>
      ) : (
        <></>
      )}
      {colors.map((color) => {
        return (
          <ColorInputElement
            color={color}
            canChangeLabel={canChangeLabel}
            setColorLabel={setColorLabel}
            setColorValue={setColorValue}
            labelIsValid={labelIsValid}
            canBeRemoved={canChangeLength}
            removalDisabled={colors.length < 3}
            handleRemoveColor={handleRemoveColor}
            key={color.label}
          />
        );
      })}
    </div>
  );
};

const ColorInputElement = ({
  color,
  setColorValue,
  setColorLabel,
  labelIsValid = {
    function(label) {
      return true;
    },
  },
  canBeRemoved = false,
  handleRemoveColor,
  canChangeLabel = true,
  removalDisabled = false,
}) => {
  const [labelInput, setLabelInput] = useState(color.label);
  const [textColorInput, setTextColorInput] = useState(color.value);

  const handleActiveInputCheck = (input) => {
    setTextColorInput(() => input);
  };

  const handleColorInputSubmit = () => {
    console.log(textColorInput);
    var parsedColorCode = parseColorCode(textColorInput);
    if (parsedColorCode != null) {
      setColorValue(color.label, parsedColorCode);
    } else {
      setTextColorInput(() => color.value);
    }
  };

  const handleColorLabel = () => {
    if (labelIsValid(labelInput)) {
      setColorLabel(color.label, labelInput);
    } else {
      setLabelInput(() => color.label);
    }
  };

  useEffect(() => {
    setLabelInput(() => color.label);
    setTextColorInput(() => color.value);
  }, [color.label, color.value]);

  return (
    <div
      className={"color-input-element " + (canBeRemoved ? "custom" : "static")}
    >
      {canChangeLabel ? (
        <input
          type="text"
          value={labelInput}
          onChange={(e) => setLabelInput(() => e.target.value)}
          onClick={(e) => e.target.select()}
          onBlur={handleColorLabel}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              handleColorLabel();
            }
          }}
        ></input>
      ) : (
        <label htmlFor="color-input">{color.label}</label>
      )}
      <div>
        <input
          type="color"
          value={color.value}
          onChange={(e) => setColorValue(color.label, e.target.value)}
          id="color-input"
        ></input>
        <input
          type="text"
          value={textColorInput}
          onChange={(e) => handleActiveInputCheck(e.target.value)}
          onBlur={handleColorInputSubmit}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              handleColorInputSubmit();
            }
          }}
          onClick={(e) => e.target.select()}
        ></input>
      </div>
      {canBeRemoved ? (
        <button
          disabled={removalDisabled}
          onClick={() => handleRemoveColor(color.label)}
        >
          -
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};
