import React, { useState, useEffect } from "react";
import CopyIcon from "./content_copy_FILL0_wght400_GRAD0_opsz24.svg";
import {
  linearColorMixer,
  parseRGB,
  getContrastRatio,
  ratioArray,
  bezierColorMixer,
  sineColorMixer,
} from "./colorFunctions";
import SwapIcon from "./swap_horiz_FILL0_wght400_GRAD0_opsz24.svg";
import { ColorInputArray } from "./colorInputs";
import ToggleButton from "./toggleButton";

const interpolationTypes = ["linear", "bezier", "sine"];

const ColorRange = () => {
  const [parentColors, setParentColors] = useState([
    { label: "Top Colour", value: "#000000" },
    { label: "Bottom Colour", value: "#ffffff" },
  ]);

  const [interpolation, setInterpolation] = useState(interpolationTypes[0]);
  const [isLinear, setIsLinear] = useState(true);
  const [bezierShape, setBezierShape] = useState("concave");

  const [numShades, setNumShades] = useState(8);
  const [baseName, setBaseName] = useState("");
  const [ratios, setRatios] = useState(
    Array.apply(null, Array(8)).map((x, i) => ((7 - i) * 100.0) / 7)
  );

  const handleNumShades = (newNum) => {
    if (!isNaN(newNum)) {
      if (newNum > 51) {
        newNum = 51;
      } else if (newNum < 2) {
        newNum = 2;
      }
      setNumShades(() => newNum);
      setRatios(() => ratioArray(newNum));
    }
  };

  const parsedBaseName = () => {
    var parsedName = baseName.replace(/[\s][\s]+/g, " ");
    parsedName = parsedName.replace(/^[\s]/, "");
    parsedName = parsedName.replace(/[\s]/g, "-");
    parsedName = parsedName.replace(/[^a-zA-Z0-9-]/g, "");
    parsedName = parsedName.toLowerCase();
    return parsedName;
  };

  const handleCopy = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleSwap = () => {
    setParentColors(() => [
      { label: parentColors[0].label, value: parentColors[1].value },
      { label: parentColors[1].label, value: parentColors[0].value },
    ]);
  };

  return (
    <div className="main-component">
      <h1>Colour Range Maker</h1>
      <div className="color-range-slider">
        <label>2</label>
        <input
          type="range"
          max={51}
          min={2}
          step={1}
          onChange={(e) => handleNumShades(parseInt(e.target.value))}
          value={numShades}
          id="color-range-slider"
        ></input>
        <label>51</label>
        <span htmlFor="color-range-slider">
          <input
            max={51}
            min={2}
            type="number"
            value={numShades}
            onChange={(e) => handleNumShades(parseInt(e.target.value))}
          ></input>{" "}
          Shades
        </span>
        <input
          type="text"
          placeholder="Custom Color Base Name"
          value={baseName}
          onChange={(e) => setBaseName(() => e.target.value)}
          onClick={(e) => e.target.select()}
        ></input>
      </div>
      <div className="color-range-inputs">
        <ColorInputArray colors={parentColors} setColors={setParentColors} />
        <button onClick={handleSwap}>
          <img src={SwapIcon}></img> Swap Colors
        </button>
        <button
          onClick={() =>
            handleCopy(
              ratios
                .map((ratio) => {
                  let colorMix;
                  switch (interpolation) {
                    case "linear":
                      colorMix = linearColorMixer(
                        parseRGB(parentColors[0].value),
                        parseRGB(parentColors[1].value),
                        ratio / 100.0
                      );
                      break;
                    case "bezier":
                      colorMix = bezierColorMixer(
                        parseRGB(parentColors[0].value),
                        parseRGB(parentColors[1].value),
                        ratio / 100.0,
                        bezierShape == "concave"
                      );
                      break;
                    case "sine":
                      colorMix = sineColorMixer(
                        parseRGB(parentColors[0].value),
                        parseRGB(parentColors[1].value),
                        ratio / 100.0
                      );
                      break;
                    default:
                      colorMix = linearColorMixer(
                        parseRGB(parentColors[0].value),
                        parseRGB(parentColors[1].value),
                        ratio / 100.0
                      );
                      break;
                  }

                  return (
                    "--" +
                    (parsedBaseName() == ""
                      ? "color-range"
                      : parsedBaseName()) +
                    "-" +
                    Math.floor(ratio) +
                    ": " +
                    colorMix +
                    "; "
                  );
                })
                .join("\n")
            )
          }
        >
          <img src={CopyIcon}></img> Copy Range CSS
        </button>
      </div>
      <div class="range-type">
        <div class="range-type-selection">
          {interpolationTypes.map((type) => {
            return (
              <div
                class={
                  "range-type-option " + interpolation == type
                    ? "yes"
                    : "unselected"
                }
              >
                <input
                  type="radio"
                  name="interpolation"
                  id={type}
                  value={type}
                  checked={interpolation == type}
                  onClick={() => setInterpolation(() => type)}
                ></input>
                <label htmlFor={type}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </label>
              </div>
            );
          })}
        </div>
        {interpolation == "bezier" ? (
          <ToggleButton
            options={["concave", "convex"]}
            selected={bezierShape}
            setSelected={setBezierShape}
          ></ToggleButton>
        ) : (
          <></>
        )}
      </div>
      <div className="color-list">
        {ratios.map((ratio) => {
          let colorMix;
          switch (interpolation) {
            case "linear":
              colorMix = linearColorMixer(
                parseRGB(parentColors[0].value),
                parseRGB(parentColors[1].value),
                ratio / 100.0
              );
              break;
            case "bezier":
              colorMix = bezierColorMixer(
                parseRGB(parentColors[0].value),
                parseRGB(parentColors[1].value),
                ratio / 100.0,
                bezierShape == "concave"
              );
              break;
            case "sine":
              colorMix = sineColorMixer(
                parseRGB(parentColors[0].value),
                parseRGB(parentColors[1].value),
                ratio / 100.0
              );
              break;
            default:
              colorMix = linearColorMixer(
                parseRGB(parentColors[0].value),
                parseRGB(parentColors[1].value),
                ratio / 100.0
              );
              break;
          }

          let blackContrast = getContrastRatio("#000000", colorMix);
          return (
            <div
              className="color-list-item"
              style={{
                background: colorMix,
                color: blackContrast >= 7 ? "black" : "white",
              }}
              key={ratio}
            >
              <p>
                {"--" +
                  (parsedBaseName() == "" ? "color-range" : parsedBaseName()) +
                  "-" +
                  Math.floor(ratio) +
                  ": " +
                  colorMix}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ColorRange;
